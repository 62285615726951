import React, { memo, useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { faBars, faBook, faInfoCircle, } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import authService from 'components/api-authorization/AuthorizeService';
import ClipLoader from 'react-spinners/ClipLoader';
import * as Logger from 'loglevel';

//Redux: 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as HubLogoActions from 'store/actions/HubLogoActions';
import * as HubSettingsActions from 'store/actions/HubSettingsActions';
import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';
import { mapDispatchToProps } from 'store/actions/HubConfigActions';
import * as HubUserBoardActions from 'store/actions/HubUserBoardActions'

import 'styles/sections/SideNavigation.scss';
import  FolderTree  from '../../widgets/FolderTree/FolderTree';

const SideNavigation = memo(function SideNavigation(props) {

    const [mobileNavBarExpanded, setMobileNavBarExpanded] = useState(false);
    const [mobileNavColor, setMobileNavColor] = useState("#FFFFFF");
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hasBeenCalledRef, setHasBeenCalledRef] = useState(false);
    const history = useHistory();


    useEffect(() => {
        const fetchNavBarInfo = async () => {
            try {
                await props.startLogoFetch();
                await props.getHubInfo();
                await props.getRootFolders();
                try {
                    await props.getFolderTreeV2().then(() => {
                        
                        setIsLoading(false);
                    });
                } catch (error) {
                    Logger.debug('Error occurred while fetching folder tree: ', error);
                    setError("Failed to Load Folder Tree");
                    setIsLoading(false);
                }

                await props.requestData();
            } catch (error) {
                Logger.debug('Error occurred while fetching data: ', error);
                setIsLoading(false);
            }
        };

        fetchNavBarInfo();
    }, []);



    useEffect(() => {
        const setDefaultTreeData = async () => {
            if (props.hubRootFolders.length > 0 && !hasBeenCalledRef) {
                const defaultFolder = props.hubRootFolders[0];
                props.setWorkingFolder(defaultFolder);
                await props.getNewsItemsByFolder(defaultFolder.id);

                if (history.location.pathname === '/newsfeed') {
                    props.setActivePageName({ singular: "Story", plural: defaultFolder.name, icon: faBook });
                }
                setHasBeenCalledRef(true);
            }
        };
        setDefaultTreeData();
    }, [props.hubRootFolders]);

    const handleFolderSelect = async (folder) => {

        await props.getNewsItemsByFolder(folder.id);
        props.setWorkingFolder(folder);
        props.setActivePageName({ singular: "Story", plural: folder.name, icon: faBook });
    }

    const updateMobileNavBarExpanded = () => {
        setMobileNavBarExpanded(!mobileNavBarExpanded, setMobileNavColor(mobileNavBarExpanded ? "#FFFFFF" : "#ff8c00"));
    }

    console.log("hubInfo: ", authService.getHubInfo())
    console.log("hubNodes: ", authService.getHubNodes())
    //Set classes below to slide in/slide out depending on the state for nav-bar animation
    const horizontalCollapseClasses = ['slideHorizontal'];
    if (mobileNavBarExpanded) {
        horizontalCollapseClasses.push('slideIn');
    } else {
        horizontalCollapseClasses.push('slideOut');
    }

    return (
        <div>
            <div onClick={updateMobileNavBarExpanded}>
                <FontAwesomeIcon
                    className="mobileNavActivator"
                    icon={faBars}
                    style={{ color: mobileNavColor }}></FontAwesomeIcon>
            </div>
            <div className={`SideNavbar ${horizontalCollapseClasses.join(' ')}`}>
                <div className="sideNavBackground"></div>
                <Navbar className={`shadow sideNavMain`}>
                    <Nav className="flex-column">
                        {
                            (!!props && !!props.logoObj)
                                ?
                                <img className="imgCard" src={props.logoObj} alt="Logo"></img>
                                :
                                <img className="imgCard" src="/images/HubBrandingDefault-1.2-Grey.png" alt="Logo"></img>
                        }
                        <div className="FolderTreeSideNav">
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <ClipLoader color="#f69b1d" size={40} />
                                </div>
                            ) : (
                                <FolderTree
                                    displayMode={false}
                                    handleFolderSelect={handleFolderSelect}
                                    workingFolder={props.hubWorkingFolder}
                                    folders={props.hubFolderTree}
                                />
                            )}
                            {error && <div className="errorMsg" style={{ textAlign: "center" }}>{error}</div>}
                        </div>
                    </Nav>
                </Navbar>
                <div className="NavBarFooter">
                    <Row>
                        <Col xs="6">
                            <Link
                                to="/about">
                                <img className="imgCard" src="/images/burli_ID_colour.png" alt="Footer"></img>
                            </Link>
                        </Col>
                        <Col xs="5">
                            <h5>{
                                !!props && !!props.hubInfo ?
                                    !!props.hubInfo.hubInfo.customerName ?
                                        props.hubInfo.hubInfo.customerName :
                                        props.hubInfo.appName :
                                    !!authService.getHubInfo() ?
                                        !!authService.getHubInfo().hubInfo.customerName ?
                                            authService.getHubInfo().hubInfo.customerName :
                                            authService.getHubInfo().appName :
                                        "Burli NewsHub"
                            }
                                <br /><span className="hubSpecs">Version: &nbsp;{
                                    (!!props && !!props.hubInfo) ? props.hubInfo.appVersion
                                        : (!!authService.getHubInfo()) ? authService.getHubInfo().appVersion
                                            : ""}
                                    <br />
                                    <Link
                                        to="/about"
                                        className="AboutButton"
                                    /*onClick={() => props.handleSetActiveNav(NavButton.SUBMENU_ITEM_B)}*/>
                                        <FontAwesomeIcon className="fa-arrows" icon={faInfoCircle} />
                                        About
                                    </Link>
                                </span></h5>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>

    );
});


const mapLogoAndSettingStatesToProps = (state) => {
  return {
      logoObj: state.hubConfig.hubLogo.logoObj,
      hubInfo: state.hubConfig.hubSettings.hubInfo,
      nodes: state.hubConfig.nodeConfig.nodes,
      hubRootFolders: state.hubObjectContent.hubRootFolders,
      hubFolderTree: state.hubObjectContent.hubFolderTree,
      hubWorkingFolder: state.hubObjectContent.hubWorkingFolder,
      users: state.hubConfig.userConfig.users,
  }
}

export default withRouter(connect(
    mapLogoAndSettingStatesToProps,
    dispatch => bindActionCreators({
        ...mapDispatchToProps,
        ...HubSettingsActions.mapSettingsDispatcherToProps,
        ...HubLogoActions.mapLogoDispatcherToProps,
        ...mapDispatchToHubObjectBoardProps,
        ...HubUserBoardActions.mapDispatchToProps,
    }, dispatch)
)(SideNavigation));